/**
 * Not meant for production.
 * Used to isolate individual react components
 */
import React from "react";
import Layout from "../components/LayoutRoot";

import { CreateTeam } from "../components/dashboard/Create";

export default () => (
  <Layout>
    <CreateTeam />
  </Layout>
);
